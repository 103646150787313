import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { getCookie, setPageData, wordsLimit, removeHTML, clickElement, 
    // offlineCheck 
} from "@/functions/general.js";
import { useChatStore } from "./chat.js";
import { doctors } from "@/equipments/dummies.js";
import { createCalendar, availableCalendar, getDay, getMonthNum } from "@/functions/calendar.js";
import { useTransactionStore } from "./transaction.js";
import { clearLine } from "@/functions/doctor.js";
// import { sessionExpiration } from "@/equipments/settings.js";

export const useDoctorStore = defineStore("doctor", {
    state: () => ({
        // ==================== states used on doctor page and its relation
        allDoctors: null,
        filteredDoctors: null,
        specialists: null,
        filteredSpecialist: null,
        specialistShow: null,
        facilities: null,
        filteredFacilities: null,
        facilitiesShow: null,
        searchbarSelectSpecialist: {id: null, input: {value: ''}},
        searchbarSelectFacility: {id: null, input: {value: ''}},
        searchbarSpecialist: {select: {id: null, title: 'Pilih Spesialis', value: 'all'}, facility: {id: null, title: 'Pilih Rumah Sakit'}, input: {value: null}},
        doctorListByCategory: null,
        doctorListByCategoryShow: null,
        searchbarDoctor: {input: {value: null}},
        doctors: null,
        specialistActive: null,
        doctorListByCategoryAndHealthFacility: null,
        doctorProfile: null,
        doctorDetail: null,
        doctorSchedule: null,
        doctorCalendar: null,
        reservationSchedule: null,
        selectedSchedule: null,
        doctorServices: null,
        autoSelectSpecialistData: null,
        homeDoctors: null,
        fdLoading: false,
        metaPage: null,
        nextLoading: false,
    }),
    getters: {
        // =====
    },
    actions: {
        // ==================== actions used on doctor page and its relation
        async getAllDoctors(){
            // console.log(this.searchbarSpecialist);
            // this.resetFilter();
            // offlineCheck('Anda sedang offline, kami mungkin menampilkan data secara offline jika tersedia');
            try{
                if(!this.allDoctors){
                    this.filteredDoctors = null;
                    const response = await axiosClient.get(`doctors`);
                    this.allDoctors = response.data.data;
                    this.metaPage = response.data.meta;
                    // console.log(response);
                    // console.log(this.allDoctors);
                    // console.log('size : ' + roughSizeOfObject(this.allDoctors) + ' bytes');
                }
            }catch(error){
                console.log(error);
            }
        },
        async setSpecialists(){
            const response = await axiosClient.get(`specialization/content`);
            this.specialists = response.data.data;
            this.filteredSpecialist = response.data.data;
            this.specialistShow = response.data.data;
            this.doctors = doctors;
            // console.log(response);
        },
        async setFacilities(){
            const response = await axiosClient.get(`facilities`);
            this.facilities = response.data.data;
            this.filteredFacilities = response.data.data;
            this.facilitiesShow = response.data.data;
            // this.doctors = doctors;
            // console.log(this.facilities);
            // console.log(this.searchbarSpecialist);
        },
        async setDlbc(slug){
            this.doctorListByCategory = null;
            this.doctorListByCategoryShow = null;
            const response = await axiosClient.get(`specialization/content/` + slug);
            this.specialistActive = response.data.data.name;
            this.doctorListByCategory = response.data.data.doctors;
            this.doctorListByCategoryShow = response.data.data.doctors;
            // this.searchDoctor();
            // console.log(this.doctorListByCategory);
        },
        async setDlbcahf(sp, hf){
            this.doctorListByCategory = null;
            const response = await axiosClient.get(`doctor/specialist/` + sp + `/` + hf);
            this.specialistActive = response.data.data.name;
            this.doctorListByCategoryAndHealthFacility = response.data.data;
            // console.log('sp : ' + sp + ' hf : ' + hf);
            // console.log(response);
        },
        async setDoctorProfile(spslug, slug){
            // console.log(window);
            // setCookie('currentLocation', window.location.href, sessionExpiration);
            scrollTo(0, 0);
            this.doctorProfile = null;
            try{
                const response = await axiosClient.get(`doctor/detail/${slug}?specialization_slug=${spslug == 'peninjau' ? '' : spslug}`, {
                    headers: {
                        "Page": window.location.origin + `/dokter/${spslug}/${slug}`
                    }
                });
                if(response.data.success){
                    response.data.data.others = [];
                    this.doctorProfile = response.data.data;
                    // console.log(this.doctorProfile);
                    setPageData({
                        title: this.doctorProfile.name,
                        description: wordsLimit(removeHTML(this.doctorProfile.description), 200),
                        linkTag: {
                            rel: 'canonical',
                            href: 'https://melindahospital.com/dokter/' + spslug + '/' + slug
                        },
                        ogData: {
                            title: this.doctorProfile.name,
                            type: 'website',
                            url: 'https://melindahospital.com/dokter/' + spslug + '/' + slug,
                            image: this.doctorProfile.profile,
                            description: wordsLimit(removeHTML(this.doctorProfile.content), 200)
                        }
                    });
                    if(this.doctorProfile.service){
                        const services = {chat: [], reservation: []};
                        for(var i = 0; i < this.doctorProfile.service.length; i++){
                            if(this.doctorProfile.service[i].type == 'consultation'){
                                services.chat.push(this.doctorProfile.service[i]);
                            }else if(this.doctorProfile.service[i].type == 'reservation'){
                                services.reservation.push(this.doctorProfile.service[i]);
                            }
                        }
                        this.doctorServices = services;
                        // console.log(this.doctorServices);
                    }
                    setTimeout(function(){
                        clearLine('other-information');
                        clickElement('acbutton-ddpractice-0');
                    }, 500);
                    if(spslug == 'peninjau'){
                        window.history.replaceState(null, '', `/dokter/${this.doctorProfile.specialist_slug}/${slug}`);
                    }
                    this.setRelatedDoctors(slug);
                }else{
                    location.href = process.env.BASE_URL + 'not-found';
                }
                // this.refreshDoctorDetail();
                // console.log(response);
            }catch(error){
                console.log(error);
                location.href = process.env.BASE_URL + 'not-found';
            }
        },
        async setRelatedDoctors(slug){
            try{
                const response = await axiosClient.get(`doctor/related/` + slug);
                this.doctorProfile.others = response.data.data.others;
                // console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async setDoctorDetail(spslug, slug){
            this.doctorDetail = null;
            try{
                const response = await axiosClient.get(`doctor/` + slug);
                this.doctorDetail = response.data.data;
                // console.log(this.doctorDetail);
                setPageData({
                    title: this.doctorDetail.user.name,
                    description: wordsLimit(removeHTML(this.doctorDetail.description), 200),
                    linkTag: {
                        rel: 'canonical',
                        href: 'https://melindahospital.com/dokter/' + spslug + '/' + slug
                    },
                    ogData: {
                        title: this.doctorDetail.user.name,
                        type: 'website',
                        url: 'https://melindahospital.com/dokter/' + spslug + '/' + slug,
                        image: this.doctorDetail.profile,
                        description: wordsLimit(removeHTML(this.doctorDetail.content), 200)
                    }
                });
                if(this.doctorDetail.service){
                    const services = {chat: [], reservation: []};
                    for(var i = 0; i < this.doctorDetail.service.length; i++){
                        if(this.doctorDetail.service[i].type == 'consultation'){
                            services.chat.push(this.doctorDetail.service[i]);
                        }else if(this.doctorDetail.service[i].type == 'reservation'){
                            services.reservation.push(this.doctorDetail.service[i]);
                        }
                    }
                    this.doctorServices = services;
                    // console.log(this.doctorServices);
                }
                setTimeout(function(){
                    clearLine('other-information');
                    clickElement('acbutton-ddpractice-0');
                }, 500);
                this.refreshDoctorDetail();
                // console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        resetFilter(){
            this.searchbarSelectSpecialist = {id: null, input: {value: ''}};
            this.searchbarSpecialist = {select: {id: null, title: 'Pilih Spesialis', value: 'all'}, facility: {id: null, title: 'Pilih Rumah Sakit'}, input: {value: null}};
            this.filteredDoctors = null;
        },
        refreshDoctorDetail(){
            this.doctorCalendar = null;
            this.doctorSchedule = null;
            this.reservationSchedule = null;
            this.selectedSchedule = null;
            useTransactionStore().directOrder = null;
            // if(document.getElementById('sbt-practice')){
            //     const practice = document.getElementById('sbt-practice');
            //     practice.children[0].children[0].innerHTML = 'Pilih Tempat Praktik';
            // }
        },
        setDoctorSchedule(month, year, schedules){
            this.doctorSchedule = null;
            this.reservationSchedule = null;
            this.selectedSchedule = null;
            schedules.forEach((element) => {
                if(element.day.toLowerCase() == 'senin'){
                    element.day_id = 1;
                }else if(element.day.toLowerCase() == 'selasa'){
                    element.day_id = 2;
                }else if(element.day.toLowerCase() == 'rabu'){
                    element.day_id = 3;
                }else if(element.day.toLowerCase() == 'kamis'){
                    element.day_id = 4;
                }else if(element.day.toLowerCase() == 'jumat'){
                    element.day_id = 5;
                }else if(element.day.toLowerCase() == 'sabtu'){
                    element.day_id = 6;
                }else if(element.day.toLowerCase() == 'minggu'){
                    element.day_id = 7;
                }
            });
            this.doctorSchedule = {head: {selected: {location: null, time: null}}, body: {data: schedules}};
            this.setDoctorCalendar(month, year);
            // console.log(this.doctorSchedule);
        },
        selectPracticeLocation(practice){
            this.doctorSchedule.head.selected.location = practice.facility;
            // console.log(this.doctorSchedule);
        },
        selectPracticeTime(schedule){
            this.doctorSchedule.head.selected.time = schedule;
            // console.log(this.doctorSchedule);
        },
        setDoctorCalendar(month, year){
            this.doctorCalendar = availableCalendar(createCalendar(month, year), this.doctorSchedule.body.data);
        },
        // clearDoctorCalendar(){
        //     this.doctorCalendar = null;
        // },
        setReservationSchedule(day, date, title){
            const dayname = getDay(day);
            if(day == 0){
                day = 7;
            }
            this.reservationSchedule = {head: {day: dayname, date: date, date_text: dayname + ', ' + date + ' ' + title, date_format: getMonthNum(date + ' ' + title), title: date + ' ' + title}, body: {schedules: []}};
            for(var i = 0; i < this.doctorSchedule.body.data.length; i++){
                if(this.doctorSchedule.body.data[i].day_id == day){
                    this.reservationSchedule.body.schedules.push(this.doctorSchedule.body.data[i]);
                    // console.log(this.doctorSchedule.body.data[i]);
                }
            }
            // console.log(this.reservationSchedule);
            // console.log(this.doctorSchedule.body.data);
            // console.log(day);
        },
        selectSchedule(schedule){
            this.selectedSchedule = schedule;
            // console.log(this.selectedSchedule);
        },
        async rateDoctor(params){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`doctor/rate`, params);
                useChatStore().syncChats();
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        inputSelectSpecialist(value){
            this.searchbarSelectSpecialist.input.value = value;
            this.searchDoctorAndSpecialistFromServer();
            if(this.specialists && this.allDoctors){
                if(value.length){
                    var newsd = [];
                    newsd = this.specialists;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredSpecialist = result;
                }else{
                    this.filteredSpecialist = this.specialists;
                }
            }
        },
        selectSpecialist(specialist){
            this.searchbarSpecialist.select = {id: specialist.id, title: specialist.name, value: specialist.slug};
            this.searchDoctorAndSpecialistFromServer();
            this.filteredSpecialist = this.specialists;
        },
        inputSpecialist(value){
            this.searchbarSpecialist.input.value = value;
            if(this.searchbarSelectSpecialist.input.value == ''){
                // console.log('input specialist');
                this.searchDoctorAndSpecialistFromServer();
                // if(value != ''){
                // }else{
                //     this.filteredDoctors = null;
                // }
                // this.searchDoctorAndSpecialist();
            }else{
                this.beforeSelectedSpecialist();
            }
            // console.log(value);
        },
        inputSelectFacility(value){
            this.searchbarSelectFacility.input.value = value;
            this.searchDoctorAndSpecialistFromServer();
            if(this.facilities && this.allDoctors){
                if(value.length){
                    var newsd = [];
                    newsd = this.facilities;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredFacilities = result;
                }else{
                    this.filteredFacilities = this.facilities;
                }
            }
        },
        selectFacility(facility){
            this.searchbarSpecialist.facility = {id: facility.id, title: facility.name, value: facility.slug};
            this.searchDoctorAndSpecialistFromServer();
            this.filteredFacilities = this.facilities;
        },
        beforeSelectedSpecialist(){
            var newsdx = [];
            newsdx = this.allDoctors;
            if(this.filteredSpecialist){
                var resultx = [];
                for(var i = 0; i < this.filteredSpecialist.length; i++){
                    for(var j = 0; j < newsdx.length; j++){
                        for(var k = 0; k < newsdx[j].specializations.length; k++){
                            if(newsdx[j].specializations[k].slug == this.filteredSpecialist[i].slug){
                                resultx.push(newsdx[j]);
                            }
                        }
                    }
                }
                if(resultx.length){
                    var resulty = [];
                    var inval = new RegExp(this.searchbarSpecialist.input.value, 'gi');
                    for(i = 0; i < resultx.length; i++){
                        if(resultx[i].user.name.match(inval)){
                            resulty.push(resultx[i]);
                        }
                    }
                    this.filteredDoctors = resulty;
                }else{
                    this.filteredDoctors = [];
                }
            }else{
                this.filteredDoctors = null;
            }
        },
        searchDoctorAndSpecialist(){
            if(this.allDoctors){
                var newsd = this.allDoctors;
                var result = [];
                if(this.searchbarSpecialist.select.value != 'all'){ // if selected specialist
                    for(var j = 0; j < newsd.length; j++){
                        for(var k = 0; k < newsd[j].specializations.length; k++){
                            if(newsd[j].specializations[k].slug == this.searchbarSpecialist.select.value){
                                result.push(newsd[j]);
                            }
                        }
                    }
                }else{
                    if(this.searchbarSpecialist.input.value){
                        result = this.allDoctors;
                    }
                }
                var resultx = [];
                if(this.searchbarSpecialist.input.value){
                    var inval = new RegExp(this.searchbarSpecialist.input.value, 'gi');
                    for(var i = 0; i < result.length; i++){
                        // console.log(result[i]);
                        if(result[i].user.name.match(inval)){
                            resultx.push(result[i]);
                        }
                    }
                }else{
                    resultx = result;
                }
                if(resultx.length){
                    this.filteredDoctors = resultx;
                }else{
                    this.filteredDoctors = null;
                }
                // console.log(this.filteredDoctors);
            }
        },
        async searchDoctorAndSpecialistFromServer(){
            try{
                const namequery = this.searchbarSpecialist.input.value;
                const spquery = this.searchbarSelectSpecialist.input.value;
                const spcid = this.searchbarSelectSpecialist.id;
                const fctid = this.searchbarSelectFacility.id;
                if((namequery == null || namequery == '') && (spquery == null || spquery == '') && (spcid == null || spcid == '') && (fctid == null || fctid == '')){
                    // console.log('enter here');
                    this.filteredDoctors = null;
                }else{
                    this.fdLoading = true;
                    this.metaPage = null;
                    var endpoint = `doctors/list`;
                    const params = {
                        search: namequery,
                        specialization: spquery,
                        specialization_id: spcid,
                        facility_id: fctid
                    };
                    // console.log(this.fdLoading);
                    const response = await axiosClient.get(endpoint, {params: params});
                    this.filteredDoctors = response.data.data;
                    this.fdLoading = false;
                    this.metaPage = response.data.meta;
                    console.log(response);
                    console.log(`namequery: ${namequery}, spquery: ${spquery}, spcid: ${spcid}, fctid: ${fctid}`);
                    // console.log(this.filteredDoctors);
                    // console.log(this.fdLoading);
                    console.log(response);
                    // if(this.searchbarSelectSpecialist.id == null){
                    //     endpoint = `doctors?name=${namequery}&specialization=${spquery}`;
                    // }
                    // console.log(this.searchbarSelectSpecialist);
                    // const spcid = this.searchbarSelectSpecialist.id;
                    // this.filteredDoctors = null;
                    // this.allDoctors = response.data.data;
                    // console.log(this.allDoctors);
                    // if(!this.allDoctors){
                    //     // console.log('size : ' + roughSizeOfObject(this.allDoctors) + ' bytes');
                    // }
                }
                // console.log(`namequery: ${namequery}, spquery: ${spquery}, spcid: ${spcid}`);
            }catch(error){
                this.fdLoading = false;
                console.log(error);
            }
        },
        searchSpecialist(){
            var newsd = [];
            newsd = this.specialists;
            // console.log(newsd);
            if(this.searchbarSpecialist.input.value){
                var result = [];
                var inval = new RegExp(this.searchbarSpecialist.input.value, 'gi');
                for(var i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.specialistShow = result;
            }else{
                this.specialistShow = newsd;
            }
        },
        inputDoctorList(value){
            this.searchbarDoctor.input.value = value;
            this.searchDoctor();
        },
        searchDoctor(){
            var newsd = [];
            newsd = this.doctorListByCategory;
            // console.log(newsd);
            if(this.searchbarDoctor.input.value){
                var result = [];
                var inval = new RegExp(this.searchbarDoctor.input.value, 'gi');
                for(var i = 0; i < newsd.length; i++){
                    if(newsd[i].doctor.user.name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.doctorListByCategoryShow = result;
            }else{
                this.doctorListByCategoryShow = newsd;
            }
        },
        async setHomeDoctors(){
            try{
                const response = await axiosClient.get(`doctors`);
                // const article = await backend1Axios.get(`home`);
                // this.services = article.data.services;
                // for(var i = 0; i < this.services.length; i++){
                //     this.services[i].slug = createSlug(this.services[i].name);
                // }
                // this.rooms = article.data.rooms;
                // this.articles = article.data.news;
                this.homeDoctors = response.data.data;
                // console.log(this.homeDoctors);
            }catch(error){
                console.log(error);
            }
        },
        async nextPage(){
            this.nextLoading = true;
            try{
                // console.log(this.nextLoading);
                const namequery = this.searchbarSpecialist.input.value;
                const spquery = this.searchbarSelectSpecialist.input.value;
                const spcid = this.searchbarSelectSpecialist.id;
                const fctid = this.searchbarSelectFacility.id;
                const page = this.metaPage.current_page + 1;
                this.metaPage = null;
                var endpoint = `doctors/list`;
                const params = {
                    name: namequery,
                    specialization: spquery,
                    specialization_id: spcid,
                    facility_id: fctid,
                    page: page
                };
                const response = await axiosClient.get(endpoint, {params: params});
                var i;
                for(i = 0; i < response.data.data.length; i++){
                    this.filteredDoctors.push(response.data.data[i]);
                }
                this.metaPage = response.data.meta;
                this.nextLoading = false;
            }catch(error){
                this.nextLoading = false;
                console.log(error);
            }
        }
    }
});



